import React from "react";
import axios from "axios";
import {
  MAIN_API_HOST,
  TIMEOUT,
  CMOTS_API_HOST,
  EKYC_API_HOST,
  EKYC_API_VER_HOST,
  X_API_KEY,
} from "./constants";
import { Context } from "../context";

export const MAIN_API = axios.create({
  baseURL: MAIN_API_HOST,
  timeout: TIMEOUT,
  headers: {
    "x-tenantCode": "SMC",
    "x-productCode": "SMCTRADEONLINE",
    "x-apikey": X_API_KEY,
    "X-Frame-Options": "sameorigin",
    key: "Content-Security-Policy",
    value: "default-src 'self' https: ; script-src 'self' ; object-src 'none'",
    "Accept-Encoding": "gzip; deflate",
    "Strict-Transport-Security": "max-age=3600 ; includeSubDomains; preload",
    "disableHostCheck": true,
    "withCredentials": true
  },

});

export const TALISMA_SMC_SEARCH_API = axios.create({
  baseURL: CMOTS_API_HOST,
  timeout: TIMEOUT,
  headers: {
    "x-tenantCode": "SMC",
    "x-productCode": "SMCTRADEONLINE",
    "x-apikey": X_API_KEY,
    "X-Frame-Options": "sameorigin",
    key: "Content-Security-Policy",
    value: "default-src 'self' https: ; script-src 'self' ; object-src 'none'",
    "Accept-Encoding": "gzip; deflate",
    "Strict-Transport-Security": "max-age=3600 ; includeSubDomains; preload",
    "disableHostCheck": true,
    "withCredentials": true
  },

});

export const CMOTS_API = axios.create({
  baseURL: CMOTS_API_HOST,
  timeout: TIMEOUT,
  headers: {
    "x-tenantCode": "SMC",
    "x-productCode": "SMCTRADEONLINE",
    "x-apikey": X_API_KEY,
    "X-Frame-Options": "sameorigin",
    key: "Content-Security-Policy",
    value: "default-src 'self' https: ; script-src 'self' ; object-src 'none'",
    "Accept-Encoding": "gzip; deflate",
    "Strict-Transport-Security": "max-age=3600 ; includeSubDomains; preload",
    "disableHostCheck": true,
    "withCredentials": true
  },

});

export const EKYC_API = axios.create({
  baseURL: EKYC_API_HOST,
  timeout: TIMEOUT,
  withCredentials: true,
});

export const EKYC_VER_API = axios.create({
  baseURL: EKYC_API_VER_HOST,
  timeout: TIMEOUT,
});

export const MAIN_REGISTER_API = axios.create({
  baseURL: MAIN_API_HOST,
  timeout: TIMEOUT,
});

export const MAIN_TOKEN_API = axios.create({
  baseURL: MAIN_API_HOST,
  timeout: TIMEOUT,
});

export const MAIN_CMOTS_URL = axios.create({
  baseURL: CMOTS_API_HOST,
  timeout: TIMEOUT,
});

const AxiosInterceptor = () => {
  const { ipAddress } = React.useContext(Context);

  React.useEffect(() => {
    const requestInterceptor = MAIN_REGISTER_API.interceptors.request.use(
      (config) => {
        config.withCredentials = true;
        config.headers["X-tenantCode"] = "SMC";
        config.headers["ip"] = ipAddress;
        config.headers["x-productCode"] = "SMCTRADEONLINE";
        config.headers["x-apikey"] = X_API_KEY;
        config.headers["X-Frame-Options"] = "sameorigin";
        config.headers["key"] = "Content-Security-Policy";
        config.headers["value"] =
          "default-src 'self' https: ; script-src 'self' ; object-src 'none'";
        config.headers["Accept-Encoding"] = "gzip; deflate";
        config.headers["Strict-Transport-Security"] = "max-age=3600 ; includeSubDomains; preload";
        config.headers["disableHostCheck"] = true;
        config.headers["withCredentials"] = true
        return config;
      },
      async (error) => {
        return await Promise.reject(error);
      }
    );
    const requestInterceptorS3 = MAIN_TOKEN_API.interceptors.request.use(
      (config) => {
        config.withCredentials = true;
        config.headers["X-tenantCode"] = "SMC";
        config.headers["ip"] = ipAddress;
        config.headers["x-productCode"] = "SMCTRADEONLINE";
        config.headers["x-apikey"] = X_API_KEY;
        config.headers["X-Frame-Options"] = "sameorigin";
        config.headers["key"] = "Content-Security-Policy";
        config.headers["value"] =
          "default-src 'self' https: ; script-src 'self' ; object-src 'none'";
        config.headers["Accept-Encoding"] = "gzip; deflate";
        config.headers["Strict-Transport-Security"] = "max-age=3600 ; includeSubDomains; preload";
        config.headers["disableHostCheck"] = true;
        config.headers["withCredentials"] = true
        return config;
      },
      async (error) => {
        return await Promise.reject(error);
      }
    );
    const requestInterceptorCmots = MAIN_CMOTS_URL.interceptors.request.use(
      (config) => {
        config.withCredentials = true;
        config.headers["X-tenantCode"] = "SMC";
        config.headers["ip"] = ipAddress;
        config.headers["x-productCode"] = "SMCTRADEONLINE";
        config.headers["x-apikey"] = X_API_KEY;
        config.headers["X-Frame-Options"] = "sameorigin";
        config.headers["key"] = "Content-Security-Policy";
        config.headers["value"] =
          "default-src 'self' https: ; script-src 'self' ; object-src 'none'";
        config.headers["Accept-Encoding"] = "gzip; deflate";
        config.headers["Strict-Transport-Security"] = "max-age=3600 ; includeSubDomains; preload";
        config.headers["disableHostCheck"] = true;
        config.headers["withCredentials"] = true
        return config;
      },
      async (error) => {
        return await Promise.reject(error);
      }
    );

    // Response interceptor
    const responseInterceptor = MAIN_REGISTER_API.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (error.response.status === 401) {
          console.log(error);
        }
        return await Promise.reject(error);
      }
    );
    const responseInterceptorS3 = MAIN_TOKEN_API.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (error.response.status === 401) {
          console.log(error);
        }
        return await Promise.reject(error);
      }
    );
    // Clean up the interceptors when the component unmounts
    return () => {
      MAIN_REGISTER_API.interceptors.request.eject(requestInterceptor);
      MAIN_TOKEN_API.interceptors.request.eject(requestInterceptorS3);
      MAIN_CMOTS_URL.interceptors.request.eject(requestInterceptorCmots);
      MAIN_REGISTER_API.interceptors.response.eject(responseInterceptor);
      MAIN_TOKEN_API.interceptors.response.eject(responseInterceptorS3);
      MAIN_CMOTS_URL.interceptors.response.eject(requestInterceptorCmots);
    };
  }, [ipAddress]);
  return null;
};
export { AxiosInterceptor };
