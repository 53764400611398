import React, { useEffect, useState } from "react";
import OTPInput, { ResendOTP } from "otp-input-react";
import { Button } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import "../../src/css/smc.css";
import { useContext } from "react";
import { Context } from "../context";
import {
  sendEkycOtpApi,
  sendOtpApi,
  sendwebinarNotification,
  updateLead,
  verifyKycOtpApi,
  verifyOtpApi,
} from "../utils/APICollection";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CAPTCHA_KEY,
  EKYC_CAPTCHA_KEY,
  EKYC_REDIRECT_URL,
} from "../utils/constants";
import {
  loadReCaptchaScript,
  loadReCaptchaScript1,
} from "./common/FormattedDate";
const OTP = () => {
  const [otp, setOTP] = useState("");
  const [error, setError] = useState("");
  const [sucess, setSucess] = useState("");
  const isMobile = window.innerWidth <= 540;
  const {
    isDemat,
    num,
    setIsDemat,
    setName,
    setNum,
    setEmail,
    setState,
    setCity,
    name,
    email,
    state,
    city,
    categoryID,
    urlData,
    ipAddress,
    setLoading,
    leadId,
    deviceID,
    setFormType,
    isLeadCapture,
    setIsLeadCapture,
    source,
    formType,
    webinarId,
  } = useContext(Context);
  // let deviceID = `${navigator.platform}`;
  const emptyField = () => {
    setCity("");
    setEmail("");
    setName("");
    setState("");
    setNum("");
  };
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  searchParams.get("refcode");
  const verifyKycOtp = async () => {
    if (otp?.length < 6) {
      setError("Invalid OTP, Please enter the correct OTP.");
      setLoading(false);
    }
    try {
      setLoading(true);
      const { data, error } = await verifyKycOtpApi({
        mobile_number: num,
        otp: otp,
      });
      if (error) {
        setLoading(false);
        setError("Invalid OTP, Please enter the correct OTP.");
      }
      if (data) {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(String(CAPTCHA_KEY), { action: "submit" })
            .then(async (token) => {
              const { data: kyc } = await updateLead({
                mobile_no: num,
                captchaToken: token,
                leadInfo: [
                  {
                    lead_id: leadId,
                    user_name: name ? name : "",
                    contact_number: num,
                    email: email,
                    city: city,
                    state: state,
                    category_code: categoryID,
                    source: source,
                    ref: urlData.ref,
                    utm_source: urlData.utm_source,
                    medium: urlData.medium,
                    campaign: urlData.campaign,
                    product: categoryID,
                    country: "",
                    ip: ipAddress,
                    device: deviceID,
                    status: data?.data?.stage,
                    client_id: "",
                    sub_query: "",
                    existing_customer: "",
                    explain_query: "",
                  },
                ],
              });
              if (kyc) {
                window.open(
                  `${EKYC_REDIRECT_URL}/e-kyc/gateway/web-trade`,
                  "_blank"
                );
                setIsDemat(false);
                navigate("/");
                setNum("");
                setLoading(false);
              } else {
                setError("Invalid OTP, Please enter the correct OTP.");
                setLoading(false);
              }
            });
        });
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    // finally {
    //   setLoading(false);
    // }
  };

  const verifyOTP = async () => {
    setLoading(true);
    if (otp?.length < 6) {
      setError("Invalid OTP, Please enter the correct OTP.");
      setLoading(false);
    } else if (isDemat) {
      verifyKycOtp();
    }
    try {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(String(CAPTCHA_KEY), { action: "submit" })
          .then(async (token) => {
            const leadInfo = [
              {
                lead_id: leadId,
                user_name: name,
                contact_number: num,
                email: email,
                city: city,
                state: state,
                category_code: categoryID,
                source: source,
                ref: urlData.ref,
                utm_source: urlData.utm_source,
                medium: urlData.medium,
                campaign: urlData.campaign,
                country: "",
                product: categoryID,
                ip: ipAddress,
                device: deviceID,
                status: "Otp verified",
                client_id: "",
                sub_query: "",
                existing_customer: "",
                explain_query: "",
              },
            ];
            const apiPayload = {
              user_name: name,
              mobile_no: num,
              email: email ? email : null,
              otp: otp,
              captchaToken: token,
              leadInfo: leadInfo,
            };
            // Conditionally include the webinar_id only if category is Webinar
            if (categoryID === "Webinar") {
              setFormType(true);
              apiPayload.webinar_id = webinarId ? webinarId : null;
            }
            const { data, error } = await verifyOtpApi(apiPayload);
            if (error) {
              setLoading(false);
              setError("Invalid OTP, Please enter the correct OTP.");
            }
            if (data?.status === "success") {
              if (categoryID === "Webinar") {
                // setFormType(true);
                setLoading(false);
                navigate("/thankyou");
              } else {
                setFormType(false);
                setIsLeadCapture(false);
                navigate("/thankyou");
                setLoading(false);
              }
            }
            // setLoading(false);
          });
      });
    } catch (error) {
      setError("Invalid OTP, Please enter the correct OTP.");
      setLoading(false);
      console.log("error", error);
    }
  };
  const sendKycOtp = async () => {
    setError("");
    setOTP("");
    try {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(String(EKYC_CAPTCHA_KEY), { action: "submit" })
          .then(async (token) => {
            const data = await sendEkycOtpApi({
              mobile_number: num,
              source: "web",
              partner_id: "smc",
              device_code: "",
              captcha_token: token,
            });
            if (data) {
              toast("OTP sent to your mobile number.");
            }
          });
      });
    } catch (error) {
      setError("Invalid Mobile number");
    }
  };
  const sendOtp = async () => {
    setError("");
    setOTP("");
    try {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(String(CAPTCHA_KEY), { action: "submit" })
          .then(async (token) => {
            const { data } = await sendOtpApi({
              mobile_no: num,
              sms_type: "sms_common",
              user_name: name,
              captchaToken: token,
            });
            if (data?.success == true) {
              toast(" OTP send to your mobile number.");
            }
          });
      });
    } catch (error) {
      setError("Invalid Mobile number");
    }
  };
  const renderButton = (buttonProps) => {
    return (
      <button {...buttonProps}>
        {buttonProps.remainingTime !== 0
          ? `Resend in ${buttonProps.remainingTime} sec`
          : "Resend OTP"}
      </button>
    );
  };
  const renderTime = () => React.Fragment;
  // if (loading) {
  //   return <Loader />;
  // }
  useEffect(() => {
    loadReCaptchaScript();
    loadReCaptchaScript1();
  }, []);
  return (
    <>
      <div className={error ? "otp_wrapper_red mt-5" : "otp_wrapper mt-5"}>
        <OTPInput
          value={otp}
          onChange={
            error
              ? () => {
                setError("");
              }
              : setOTP
          }
          // inputStyle={inputStyle}
          autoFocus
          OTPLength={6}
          otpType="number"
          disabled={false}
          placeholder={["-", "-", "-", "-", "-", "-"]}
        />
      </div>
      <div className="resend_btn" style={{ display: "flex" }}>
        <p style={{ fontSize: "14px", marginTop: 30, marginBottom: 20 }}>
          Didn't receive the OTP?{" "}
        </p>
        <ResendOTP
          maxTime="60"
          style={{
            display: "inlineFlex",
            width: 250,
            marginTop: 29,
            fontSize: 14,
            color: "#446DFF",
          }}
          renderTime={renderTime}
          renderButton={renderButton}
          onResendClick={isDemat ? sendKycOtp : sendOtp}
        />
      </div>
      {error ? <div className="login_error">{error}</div> : null}
      <Button
        style={{
          cursor: "pointer",
          marginTop: "50px",
          borderRadius: "35px",
          color: "#FFF",
          padding: "10px 40px",
          background: "linear-gradient(80deg, #405cb2, #44b6a7)",
        }}
        // fullWidth
        onClick={isDemat ? verifyKycOtp : verifyOTP}
      >
        Confirm OTP
      </Button>
    </>
  );
};
export default OTP;
