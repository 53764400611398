import React, { useContext, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Ios from "../images/app.webp";
import Android from "../images/google.webp";
import { Logo } from "./Logo";
import { ANDROID_LINK, IOS_LINK } from "../utils/linkConstants";
import { Context } from "../context";
import { ResearchSidebar } from "../pages/research/research-reports/wisemoney/research-sidebar";
import ImportantLinks from "./ImportantLinks";
import { Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { addUrl, getFileUrl } from "../utils/APICollection";
import { AWS_S3_complience_doc } from "../utils/constants";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const Footer = () => {
  const [implinks, setImpLinks] = React.useState("");
  const [localImpLinks, setLocalImpLinks] = React.useState(
    "https://scores.gov.in/scores/Welcome.html"
  );
  const {
    showPath,
    setShowPath,
    setName,
    setNum,
    setEmail,
    setState,
    setCity,
    loading,
    setLoading,
  } = useContext(Context);
  function scrollToTop() {
    window.scrollTo(0, 0);
  }
  const emptyField = () => {
    setCity("");
    setEmail("");
    setName("");
    setState("");
    setNum("");
  };
  const getUrl = async (fileName) => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const { data } = await getFileUrl({
        file_name: fileName,
        folder_name: AWS_S3_complience_doc,
      });

      if (data?.statusCode === 200) {
        window.open(data?.data?.[0]?.file_url);
        setTimeout(() => {
          setImpLinks("");
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      setImpLinks("");
    } finally {
      setImpLinks("");
      setLoading(false);
    }
  };
  const location = useLocation();
  const pathname = location.pathname; // Get the current pathname

  // Define the categories to check for
  const categories = ["equities", "ipo", "mutual-funds", "ETF"];

  // Determine the category based on the pathname
  let category = "others"; // Default to 'others'

  // Check if the pathname is the home page
  if (pathname === "/") {
    category = "Home";
  } else {
    // Loop through categories to find a match
    for (const cat of categories) {
      if (pathname.includes(cat)) {
        category = cat; // Set category to the matched keyword
        break; // Exit the loop once a match is found
      }
    }
  }

  const isMobile = window.innerWidth <= 540;
  useEffect(() => {
    const addSiteMapUrl = async () => {
      let priority = "";
      try {
        const result = await addUrl({
          pageUrl: `https://www.smctradeonline.com${pathname}`,
          category: category,
          priority:
            category === "others" ? 0.8 : category === "Home" ? 1.0 : 0.9,
        });
      } catch (error) {
        console.log(error);
      }
    };
    addSiteMapUrl();
  }, []);
  return (
    <>
      <footer>
        <div className="footer">
          <div className="container">
            <div className="row">
              <div className="footersnt pb-0">
                <div className="footerrow flex-wrap">
                  <div className="footerlogo">
                    <div className="flogo">
                      <NavLink
                        to="/"
                        onClick={() => {
                          scrollToTop();
                          emptyField();
                        }}
                      >
                        <Logo />
                      </NavLink>
                    </div>
                  </div>
                  <div className="aboutus">
                    <p>
                      Established in 1990, SMC is a diversified financial
                      services company in India offering brokerage services
                      across the asset classes of equities (cash and
                      derivatives), commodities and currency, investment
                      banking, wealth management, distribution of third party
                      financial products, research, financing, depository
                      services, insurance broking (life & non-life), clearing
                      services, mortgage advisory and real estate advisory
                      services to corporates, institutions, high net worth
                      individuals and other retail clients.
                    </p>
                    <div className="downloadimg">
                      <span>
                        <NavLink to={ANDROID_LINK} target="_blank">
                          <img
                            src={Android}
                            alt="Play Store"
                            className="responsive"
                            style={{ width: 150, height: 44 }}
                          />
                        </NavLink>
                      </span>
                      <span>
                        <NavLink to={IOS_LINK} target="_blank">
                          <img
                            src={Ios}
                            alt="IOS"
                            className="responsive"
                            style={{ width: 150, height: 44 }}
                          />
                        </NavLink>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="footerrow">
                  <div className="footercol">
                    <h5>Corporate Office</h5>
                    <div className="address">
                      <p>
                        SMC Global Securities Ltd
                        <br /> 11/6B, Shanti Chamber Pusa Road, New Delhi –
                        110005
                      </p>
                      <p>
                        <a href="tel:+91-11-30111000">Phone: +91-11-30111000</a>
                      </p>
                      <p>
                        <a href="mailto:smc@smcindiaonline.com">
                          Email: smc@smcindiaonline.com
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="footercol">
                    <h5>REGIONAL Office</h5>
                    <div className="address">
                      <p>
                        Lotus Corporate Park, A Wing 401 / 402 , 4th Floor
                        ,Graham Firth Steel Compound, Off Western Express
                        Highway, Jay Coach Signal, Goregaon (East), Mumbai -
                        400063
                      </p>
                      <p>
                        <a href="tel:+91-22-67341600/1675">
                          Phone: +91-22-67341600/1675
                        </a>
                      </p>
                      <p>
                        <a href="tel:+91-22-67341697">Email: +91-22-67341697</a>
                      </p>
                    </div>
                  </div>
                  <div className="footercol">
                    <div className="address">
                      <p>
                        18, Rabindra Sarani, Poddar Court, Gate No-4, 5th Floor
                        Kolkata-700001
                      </p>
                      <p>
                        <a href="tel:+91-33-66127000">Phone: +91-33-66127000</a>
                      </p>
                      <p>
                        <a href="tel:+91-33-40827000">Phone: +91-33-40827000</a>
                      </p>
                    </div>
                  </div>
                  <div className="footercol">
                    <div className="address">
                      <p>
                        10/A, Kalapurnam Building, Near Muncipal Market
                        C.G.Road, Ahmedabad - 380009
                      </p>
                      <p>
                        <a href="tel:91-44-39109100-122">
                          Phone: 91-44-39109100-122
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="footerrow">
                  <div className="sitemap">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: isMobile ? "100%" : "auto",
                        gap: 10,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          width: "100%",
                          justifyContent: "space-between",
                          gap: 10,
                        }}
                      >
                        <div className="sitemapcol">
                          <h6>
                            <NavLink to="/invest" onClick={emptyField}>
                              Invest
                            </NavLink>
                          </h6>
                          <div className="accordianview">
                            <Accordion allowZeroExpanded="true">
                              <AccordionItem>
                                <AccordionItemHeading>
                                  <AccordionItemButton>
                                    <NavLink
                                      // to="/invest"
                                      style={{ color: "#afafaf" }}
                                    >
                                      Invest
                                    </NavLink>
                                  </AccordionItemButton>
                                </AccordionItemHeading>

                                <AccordionItemPanel>
                                  <ul>
                                    <li>
                                      <NavLink
                                        to="/invest/equities"
                                        onClick={() => {
                                          emptyField();
                                          setShowPath(false);
                                        }}
                                      >
                                        Equities
                                      </NavLink>
                                    </li>
                                    <li>
                                      <NavLink
                                        to="/invest/mutual-fund"
                                        onClick={() => {
                                          setShowPath(false);
                                        }}
                                      >
                                        Mutual Funds
                                      </NavLink>
                                    </li>
                                    <li>
                                      <NavLink
                                        to="/invest/ipo"
                                        onClick={() => {
                                          setShowPath(false);
                                        }}
                                      >
                                        IPOs
                                      </NavLink>
                                    </li>
                                    {/* <li>
                                  <NavLink
                                    to="/invest/us-stocks"
                                    onClick={() => {
                                      setShowPath(false);
                                    }}
                                  >
                                    US Stocks
                                  </NavLink>
                                </li> */}
                                    {/* <li>
                                  <NavLink
                                    to="/fixed-income"
                                    onClick={() => {
                                      setShowPath(false);
                                    }}
                                  >
                                    Fixed Income
                                  </NavLink>
                                </li> */}
                                    {/* <li>
                                  <NavLink
                                    to="https://www.smctradeonline.com/investment-advisory-portfolios"
                                    target="_blank"
                                  >
                                    Intelligent Advisory Portfolio
                                  </NavLink>
                                </li> */}
                                    <li>
                                      <NavLink
                                        to="/invest/refer-earn"
                                        onClick={() => {
                                          setShowPath(false);
                                        }}
                                      >
                                        Refer & Earn
                                      </NavLink>
                                    </li>
                                  </ul>
                                </AccordionItemPanel>
                              </AccordionItem>
                              {/* <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                Calculator
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <ul>
                                <li>Goal Setting Calculator</li>
                                <li>MF Return Calculator</li>
                                <li>SIP Calculator</li>
                                <li>ELSS calculator</li>
                                <li>View all Calculators</li>
                              </ul>
                            </AccordionItemPanel>
                          </AccordionItem> */}
                              <AccordionItem>
                                <AccordionItemHeading>
                                  <AccordionItemButton>
                                    Holidays
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  <ul>
                                    <li>
                                      <NavLink to="/invest/trading-holidays-2024">
                                        Trading Holidays
                                      </NavLink>
                                    </li>
                                    <li>
                                      <NavLink to="/invest/nse-holiday-2024">
                                        NSE Holidays
                                      </NavLink>
                                    </li>
                                    <li>
                                      <NavLink to="/invest/bse-holiday-2024">
                                        BSE Holidays
                                      </NavLink>
                                    </li>
                                    <li>
                                      <NavLink to="/invest/mcx-holiday-2024">
                                        MCX Holidays
                                      </NavLink>
                                    </li>
                                    <li>
                                      <NavLink to="/invest/muhurat-holiday-2024">
                                        Muhurat dates
                                      </NavLink>
                                    </li>
                                  </ul>
                                </AccordionItemPanel>
                              </AccordionItem>
                            </Accordion>
                          </div>
                        </div>
                        <div className="sitemapcol">
                          <h6>
                            <NavLink to="/trade" onClick={emptyField}>
                              TRADE
                            </NavLink>
                          </h6>
                          <div className="accordianview">
                            <Accordion allowZeroExpanded="true">
                              {/* <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>Trade</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <ul>
                                <li>
                                  {" "}
                                  <NavLink to="/FuturesOptions">
                                    Futures and Options
                                  </NavLink>
                                </li>

                                <li>
                                  <NavLink to="/Commodity">Commodities</NavLink>
                                </li>
                                <li>
                                  <NavLink to="/Currency">Currency</NavLink>
                                </li>
                              </ul>
                            </AccordionItemPanel>
                          </AccordionItem> */}
                              {/* <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                Products
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <ul>
                                <li>Mobile App</li>
                                <li>Web App</li>
                                <li>Desktop</li>
                                <li>API Connect</li>
                                <li>MTF Activation</li>
                              </ul>
                            </AccordionItemPanel>
                          </AccordionItem> */}
                              <AccordionItem>
                                <AccordionItemHeading>
                                  <AccordionItemButton>
                                    Tools
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  <ul>
                                    <li>
                                      <NavLink
                                        to="https://autotrender.smcindiaonline.com:8790/"
                                        target="_blank"
                                      >
                                        Autotrender
                                      </NavLink>
                                    </li>
                                    <li>
                                      <NavLink to="/trade/algo-trading-platform">
                                        Algo Trading Platform
                                      </NavLink>
                                    </li>
                                  </ul>
                                </AccordionItemPanel>
                              </AccordionItem>
                              {/* <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                Calculators
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <ul>
                                <li>Option value calculator</li>
                                <li>Span Calculator</li>
                                <li>View all calculators</li>
                              </ul>
                            </AccordionItemPanel>
                          </AccordionItem> */}
                              <AccordionItem>
                                <AccordionItemHeading>
                                  <AccordionItemButton>
                                    Telegram Channels
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  <ul>
                                    <ul>
                                      <li>
                                        <NavLink
                                          to="https://subscriptions.smcindiaonline.com:8880/indexcalls"
                                          target="_blank"
                                        >
                                          Index Trading
                                        </NavLink>
                                      </li>
                                      <li>
                                        <NavLink
                                          to="https://subscriptions.smcindiaonline.com:8880/equityfunda"
                                          target="_blank"
                                        >
                                          Equity Ka Funda
                                        </NavLink>
                                      </li>
                                      <li>
                                        <NavLink
                                          to="https://subscriptions.smcindiaonline.com:8880/technofunda"
                                          target="_blank"
                                        >
                                          Techno Funda
                                        </NavLink>
                                      </li>
                                      <li>
                                        <NavLink
                                          to="https://subscriptions.smcindiaonline.com:8880/commodity"
                                          target="_blank"
                                        >
                                          Commodity Mantra
                                        </NavLink>
                                      </li>
                                      <li>
                                        <NavLink
                                          to="https://subscriptions.smcindiaonline.com:8880/technofunda"
                                          target="_blank"
                                        >
                                          Maha Combo
                                        </NavLink>
                                      </li>
                                    </ul>
                                  </ul>
                                </AccordionItemPanel>
                              </AccordionItem>
                            </Accordion>
                          </div>
                        </div>
                        <div className="sitemapcol">
                          <h6 style={{ cursor: "context-menu" }}>PRODUCTS </h6>
                          <div className="accordianview">
                            <Accordion allowZeroExpanded="true">
                              {/* <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>Trading</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <ul>
                                <li>Mobile App</li>
                                <li>Web App</li>
                                <li>Desktop</li>
                                <li>API Connect</li>
                                <li>MTF Activation</li>
                              </ul>
                            </AccordionItemPanel>
                          </AccordionItem> */}
                              <AccordionItem>
                                <AccordionItemHeading>
                                  <AccordionItemButton>
                                    Invest
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  <ul>
                                    <li>
                                      <NavLink
                                        to="/invest/equities"
                                        onClick={() => {
                                          setShowPath(false);
                                          emptyField();
                                        }}
                                      >
                                        Equities
                                      </NavLink>
                                    </li>
                                    <li>
                                      <NavLink
                                        to="/invest/mutual-fund"
                                        onClick={() => {
                                          setShowPath(false);
                                        }}
                                      >
                                        Mutual Funds
                                      </NavLink>
                                    </li>
                                    <li>
                                      <NavLink
                                        to="/invest/ipo"
                                        onClick={() => {
                                          setShowPath(false);
                                        }}
                                      >
                                        IPOs
                                      </NavLink>
                                      {/* <Link
                                    sx={{
                                      textDecoration: "none",
                                      cursor: "pointer",
                                    }}
                                    // onClick={handleClick}
                                  >
                                    IPOs
                                  </Link> */}
                                    </li>
                                    {/* <li>
                                  <NavLink
                                    to="/invest/us-stocks"
                                    onClick={() => {
                                      setShowPath(false);
                                    }}
                                  >
                                    US Stocks
                                  </NavLink>
                                </li> */}

                                    {/* <li>
                                  <NavLink
                                    to="/fixed-income"
                                    onClick={() => {
                                      setShowPath(false);
                                    }}
                                  >
                                    Fixed Income
                                  </NavLink>
                                </li> */}

                                    {/* <li>Intelligent Advisory Portfolio</li>
                                <li>
                                  <NavLink
                                    to="https://www.smctradeonline.com/investment-advisory-portfolios"
                                    target="_blank"
                                  >
                                    Intelligent Advisory Portfolio
                                  </NavLink>
                                </li> */}
                                    <li>
                                      <NavLink
                                        to="/invest/refer-earn"
                                        onClick={() => {
                                          setShowPath(false);
                                        }}
                                      >
                                        Refer & Earn
                                      </NavLink>
                                    </li>
                                  </ul>
                                </AccordionItemPanel>
                              </AccordionItem>
                              <AccordionItem>
                                <AccordionItemHeading>
                                  <AccordionItemButton>
                                    Tools
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  <ul>
                                    <li>
                                      <Link
                                        to="https://autotrender.smcindiaonline.com:8790/"
                                        target="_blank"
                                      >
                                        Autotrender
                                      </Link>
                                    </li>
                                    <li>
                                      <NavLink to="/trade/algo-trading-platform">
                                        Algo Trading Platform
                                      </NavLink>
                                    </li>
                                    <li>
                                      <NavLink
                                        to="https://refer.smcindiaonline.org/refer-and-earn/?sourceId=1&destinationId=4&org=smc&mobile=null"
                                        target="_blank"
                                      >
                                        Refer Subscription Products
                                      </NavLink>
                                    </li>
                                    {/* <li>Calculators</li> */}
                                  </ul>
                                </AccordionItemPanel>
                              </AccordionItem>
                              <AccordionItem>
                                <AccordionItemHeading>
                                  <AccordionItemButton>
                                    Telegram Channels
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  <ul>
                                    <li>
                                      <NavLink
                                        to="https://subscriptions.smcindiaonline.com:8880/indexcalls"
                                        target="_blank"
                                      >
                                        Index Trading
                                      </NavLink>
                                    </li>
                                    <li>
                                      <NavLink
                                        to="https://subscriptions.smcindiaonline.com:8880/equityfunda"
                                        target="_blank"
                                      >
                                        Equity Ka Funda
                                      </NavLink>
                                    </li>
                                    <li>
                                      <NavLink
                                        to="https://subscriptions.smcindiaonline.com:8880/technofunda"
                                        target="_blank"
                                      >
                                        Techno Funda
                                      </NavLink>
                                    </li>
                                    <li>
                                      <NavLink
                                        to="https://subscriptions.smcindiaonline.com:8880/commodity"
                                        target="_blank"
                                      >
                                        Commodity Mantra
                                      </NavLink>
                                    </li>
                                    <li>
                                      <NavLink
                                        to="https://subscriptions.smcindiaonline.com:8880/technofunda"
                                        target="_blank"
                                      >
                                        Maha Combo
                                      </NavLink>
                                    </li>
                                  </ul>
                                </AccordionItemPanel>
                              </AccordionItem>
                              {/* <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                Other Products
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <ul>
                                <li>Distribution</li>
                                <li>Advisory</li>
                                <li>Insurance</li>
                                <li>MTF Financing</li>
                                <li>Banking tie-ups</li>
                                <li>NRI</li>
                                <li>FPI</li>
                                <li>Clearing </li>
                                <li>NPS</li>
                              </ul>
                            </AccordionItemPanel>
                          </AccordionItem> */}
                            </Accordion>
                          </div>
                        </div>
                        <div className="sitemapcol">
                          <h6>
                            <NavLink to="/research" onClick={emptyField}>
                              RESEARCH
                            </NavLink>
                          </h6>
                          <div className="accordianview">
                            <Accordion allowZeroExpanded="true">
                              <AccordionItem>
                                <AccordionItemHeading>
                                  <AccordionItemButton>
                                    Research Reports
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  <ResearchSidebar />
                                  {/* <ul>
                                <li>
                                  <NavLink to="/research/wise-money/19">
                                    Wise Money
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to="/research/equity-analysis/16">
                                    Equity Analysis
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to="/research/derivatives-analysis/36">
                                    Derivative Analysis
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to="/research/commodities-analysis/13">
                                    Commodity Analysis
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to="/research/currency-analysis/14">
                                    Currency Analysis
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to="/research/ipo-reports/17">
                                    IPO Reports
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to="/research/mutual-fund-analysis/18">
                                    Mutual Funds Analysis
                                  </NavLink>
                                </li>
                              </ul> */}
                                </AccordionItemPanel>
                              </AccordionItem>
                              {/* <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                Stock Picks
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <ul>
                                <li>Technical Picks</li>
                                <li>Fundamental Picks</li>
                                <li>Top 10 Picks</li>
                                <li>Mutual Funds Picks</li>
                              </ul>
                            </AccordionItemPanel>
                          </AccordionItem> */}
                              <AccordionItem>
                                <AccordionItemHeading>
                                  <AccordionItemButton>
                                    Tools
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  <ul>
                                    <li>
                                      <NavLink
                                        to="https://autotrender.smcindiaonline.com:8790/"
                                        target="_blank"
                                      >
                                        Autotrender
                                      </NavLink>
                                    </li>
                                    <li>
                                      <NavLink to="/trade/algo-trading-platform">
                                        Algo Trading Platform
                                      </NavLink>
                                    </li>
                                  </ul>
                                </AccordionItemPanel>
                              </AccordionItem>
                              <AccordionItem>
                                <AccordionItemHeading>
                                  <AccordionItemButton>
                                    Research
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  <ul>
                                    <li>
                                      <Link to="/research/fundamental-research">
                                        Fundamental Research
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/research/technical-research">
                                        Technical Research
                                      </Link>
                                    </li>
                                  </ul>
                                </AccordionItemPanel>
                              </AccordionItem>
                            </Accordion>
                          </div>
                        </div>
                        <div className="sitemapcol">
                          <h6>
                            <NavLink
                              // to="/learning-centre"
                              style={{ cursor: "context-menu" }}
                            >
                              Learn
                            </NavLink>
                          </h6>
                          <div className="accordianview">
                            <Accordion allowZeroExpanded="true">
                              <AccordionItem>
                                <AccordionItemHeading>
                                  <AccordionItemButton>
                                    Learning Centre
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  <ul>
                                    <li>
                                      <NavLink
                                        to="/learning-centre/webinar"
                                        // onClick={emptyField}
                                      >
                                        Webinar
                                      </NavLink>
                                    </li>
                                    <li>
                                      <NavLink
                                        to="https://blog.smctradeonline.com:7171/"
                                        onClick={emptyField}
                                        target="_blank"
                                      >
                                        Blog
                                      </NavLink>
                                    </li>
                                    {/* <li>Demat</li>
                                <li>Trading</li>
                                <li>Online</li>
                                <li>Intraday trading</li>
                                <li>Future and Options</li>
                                <li>Mutual Funds</li>
                                <li>Share Market</li>
                                <li>IPOs</li>
                                
                                <li>Podcast</li> */}
                                  </ul>
                                </AccordionItemPanel>
                              </AccordionItem>
                              {/* <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                Future And Options
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <ul>
                                <li>Future and Options</li>
                                <li>Mutual Funds</li>
                                <li>Share Market</li>
                                <li>IPO</li>
                                <li>Help Centre</li>
                              </ul>
                            </AccordionItemPanel>
                          </AccordionItem> */}
                              {/* <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                Account Opening
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <ul>
                                <li>Post trade actions</li>
                                <li>Submit Help desk ticket</li>
                                <li>Resources</li>
                              </ul>
                            </AccordionItemPanel>
                          </AccordionItem> */}
                              {/* <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                Trading Glossary
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <ul>
                                <li>Trading Glossary</li>
                                <li>Knowledge Base</li>
                                <li>Market Talk</li>
                                <li>Investment Charter</li>
                              </ul>
                            </AccordionItemPanel>
                          </AccordionItem> */}
                            </Accordion>
                          </div>
                          <h6 style={{ marginTop: 20 }}>
                            <NavLink
                              // to="/learning-centre"
                              style={{
                                cursor: "context-menu",
                              }}
                            >
                              Banking Tie-Ups
                            </NavLink>
                          </h6>
                          <div className="accordianview">
                            <Accordion allowZeroExpanded="true">
                              <AccordionItem>
                                <AccordionItemHeading>
                                  <AccordionItemButton>
                                    Banking Tie-Ups
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  <ul>
                                    <li>
                                      <NavLink
                                        to="https://old.smctradeonline.com/pnb-smc-online-trading"
                                        onClick={emptyField}
                                        target="_blank"
                                      >
                                        PNB Online Trading
                                      </NavLink>
                                    </li>
                                    <li>
                                      <NavLink
                                        to="https://old.smctradeonline.com/iob-smc-online-trading/"
                                        onClick={emptyField}
                                        target="_blank"
                                      >
                                        IOB Online Trading
                                      </NavLink>
                                    </li>
                                    <li>
                                      <NavLink
                                        to="https://old.smctradeonline.com/union-bank-smc-online-trading/"
                                        onClick={emptyField}
                                        target="_blank"
                                      >
                                        UBI Online Trading
                                      </NavLink>
                                    </li>
                                    <li>
                                      <NavLink
                                        to="https://ekyconline.smcindiaonline.org:6061/"
                                        onClick={emptyField}
                                        target="_blank"
                                      >
                                        Dhanlaxmi Bank Online Trading
                                      </NavLink>
                                    </li>
                                    <li>
                                      <NavLink
                                        to="https://old.smctradeonline.com/kvb-smc-online-trading/kvb-smc-online-trading.aspx"
                                        target="_blank"
                                        onClick={emptyField}
                                      >
                                        KVB Online Trading
                                      </NavLink>
                                    </li>
                                    <li>
                                      <NavLink
                                        to="https://old.smctradeonline.com/groot-ujjijav-bank/groot-ujjijav-bank.aspx"
                                        target="_blank"
                                        onClick={emptyField}
                                      >
                                        Ujjivan Bank Online Trading
                                      </NavLink>
                                    </li>
                                    <li>
                                      <NavLink
                                        to="https://old.smctradeonline.com/nainital-bank/nainital-bank.aspx"
                                        target="_blank"
                                        onClick={emptyField}
                                      >
                                        Nainital Bank
                                      </NavLink>
                                    </li>
                                  </ul>
                                </AccordionItemPanel>
                              </AccordionItem>
                            </Accordion>
                          </div>
                        </div>
                        <div className="sitemapcol">
                          <h6>
                            <NavLink
                              // to="/partner-us"
                              style={{ cursor: "context-menu" }}
                            >
                              PARTNER US
                            </NavLink>
                          </h6>
                          <ul>
                            <li>
                              <NavLink
                                to="/partner-us/authorized-person"
                                onClick={emptyField}
                              >
                                Authorized Person
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                to="/partner-us/become-distributor"
                                onClick={emptyField}
                              >
                                Become Distributor
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/about-us" onClick={emptyField}>
                                About Us
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="implinks">
                        <h6>
                          Important Links
                          <div
                            style={{
                              display: "flex",
                              marginTop: 15,
                            }}
                          >
                            <div
                              style={{
                                height: 40,
                                background: "#FFF",
                                borderRadius: 20,
                                // paddingRight: isMobile ? 40 : 50,
                                width: 250,
                              }}
                            >
                              <ImportantLinks
                                setImpLinks={setImpLinks}
                                localImpLinks={localImpLinks}
                                setLocalImpLinks={setLocalImpLinks}
                                isMobile={isMobile}
                              />
                            </div>
                            <Button
                              variant="contained"
                              size="small"
                              endIcon={<ArrowForwardIcon />}
                              onClick={
                                implinks
                                  ? () => getUrl(implinks)
                                  : () => window.open(localImpLinks)
                              }
                              sx={{
                                ml: "-45px",
                                width: "100px",
                                borderRadius: "20px",
                                background:
                                  "linear-gradient(80deg, #405cb2, #44b6a7)",
                                color: "#FFF",
                                px: 7,
                                fontSize: 16,
                              }}
                            >
                              Go
                            </Button>
                          </div>
                        </h6>
                        <div className="diclaimer" style={{ display: "flex" }}>
                          <p>
                            <InfoOutlinedIcon
                              sx={{ fontSize: 14, mr: "5px" }}
                            />
                          </p>
                          <p>
                            Please quote content Ref No. while raising your
                            <br /> complaint at SEBI SCORES/Exchange portal
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="sitemapcol">
                      <h6>Follow Us</h6>
                      <ul>
                        <li>
                          <a
                            href="https://in.linkedin.com/company/smc-group?original_referer=https%3A%2F%2Fwww.google.com%2F"
                            target="_blank"
                          >
                            <i className="fa fa-linkedin"></i>
                            <span>Linked In</span>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://twitter.com/smcglobal?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                            target="_blank"
                          >
                            <i className="fa fa-twitter"></i>
                            <span>Twitter</span>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.facebook.com/smcglobalofficial"
                            target="_blank"
                          >
                            <i className="fa fa-facebook"></i>
                            <span>Facebook</span>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.youtube.com/channel/UCtTtROdCOaJlTmxuPK-gSsg"
                            target="_blank"
                          >
                            <i className="fa fa-youtube-play"></i>
                            <span>Youtube</span>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.secure.instagram.com/smcglobal.official/"
                            target="_blank"
                          >
                            <i className="fa fa-instagram"></i>
                            <span>Instagram</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="footerrow flex-wrap">
                  <div className="diclaimer">
                    <p>
                      SMC Global Securities Ltd | CIN : L74899DL1994PLC063609 |
                      Registered Office: 11/6B, Shanti Chamber, Pusa Road,
                      Delhi-110005. |{" "}
                      <a href="tel:91-11-30111000">Tel +91-11-30111000</a> |
                      Compliance Officer: Mr. Vikas Sethi |
                      <a href="tel:91-11-30111000">Tel +91-11-30111000</a> Extn.
                      340 | Email:-
                      <a
                        href="mailto: vikassethi@smcindiaonline.com"
                        data-kmt="1"
                      >
                        vikassethi@smcindiaonline.com
                      </a>
                      | Customer Care Email –
                      <a
                        href="mailto: smc.care@smcindiaonline.com"
                        data-kmt="1"
                      >
                        smc.care@smcindiaonline.com
                      </a>
                      | Complaint Email –
                      <a href="mailto: igc@smcindiaonline.com" data-kmt="1">
                        igc@smcindiaonline.com
                      </a>
                      | website:{" "}
                      <a href="https://www.smctradeonline.com/" data-kmt="1">
                        www.smctradeonline.com
                      </a>
                    </p>

                    <p>
                      SEBI Reg. No. INZ000199438, Member: NSE (07714), BSE
                      (470), MSEI (1002), MCX (8200) &amp; NCDEX (00021). DP
                      SEBI Regn. No. CDSL/NSDL-IN-DP-130-2015, SMC Research
                      Analyst Registration- INH100001849, Mutual Funds
                      Distributor ARN No. 29345. •Insurance services are offered
                      through SMC Insurance Brokers Pvt. Ltd. IRDAI Regn. No: DB
                      272/04 License No. 289 Valid upto 27/01/2026. • Real
                      Estate Advisory services are offered through SMC Real
                      Estate Advisors Pvt. Ltd.
                    </p>
                    <p style={{ fontWeight: "bold", fontSize: 14 }}>
                      ATTENTION INVESTORS
                    </p>
                    <p>
                      "1. Stock Brokers can accept securities as margin from
                      clients only{" "}
                      <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
                        by way of pledge in the depository system w.e.f.
                        September 1, 2020.
                      </span>
                      <br /> 2.
                      <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
                        Update your mobile number & email Id
                      </span>{" "}
                      with your stock broker/depository participant and receive
                      OTP directly from depository on your email id and/or
                      mobile number to create pledge.
                      <br /> 3.
                      <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
                        Pay minimum 20% upfront margin
                      </span>{" "}
                      of the transaction value to trade in cash market segment.
                      <br /> 4. Investors may please refer to the Exchange's
                      Frequently Asked Questions (FAQs) issued vide circular
                      reference NSE/INSP/45191 dated July 31, 2020 and
                      NSE/INSP/45534 dated August 31, 2020 and other guidelines
                      issued from time to time in this regard.
                      <br /> 5. Check your Securities /MF/ Bonds in the
                      consolidated account statement issued by NSDL/CDSL every
                      month.
                      <br />
                      <span style={{ fontWeight: "bolder" }}>
                        .......... Issued in the interest of Investors"
                      </span>
                      <br />
                      <br />
                      Prevent Unauthorized Transactions in your demat/trading
                      account --{">"} Update your Mobile Number/email ID with
                      your stock broker/Depository Participant. Receive
                      information of your transactions directly from Exchanges
                      on your mobile/email at the end of day and alerts on your
                      registered mobile for all debits and other important
                      transactions in your demat account directly from NSDL/CDSL
                      on the same day. - Issued in the interest of investors.
                      KYC is one time exercise while dealing in securities
                      markets - once KYC is done through a SEBI registered
                      intermediary (broker, DP, Mutual Fund etc.), you need not
                      undergo the same process again when you approach another
                      intermediary. No need to issue cheques by investors while
                      subscribing for IPO. Just write the bank account number
                      and sign-in the application form to authorise your bank to
                      make payment in case of allotment. No worries of refund as
                      the money remain in the investor's account.
                      <br />
                      For Rights and Obligation, RDD, Guidance Note in
                      Vernacular Language{" "}
                      <Link
                        className="footer-link"
                        onClick={() =>
                          getUrl("Client-Registration-Documents.rar")
                        }
                      >
                        Click Here.
                      </Link>
                    </p>
                    <p>
                      Disclaimer: Investment in securities market are subject to
                      market risks. Read all the related documents carefully
                      before investing. Mutual Fund investments are subject to
                      market risks. Read all scheme related documents carefully.
                      PMS is not offered in commodity derivative segment.
                      Insurance is the subject matter of solicitation.
                      Registration granted by SEBI and certification from NISM
                      in no way guarantee performance of the intermediary or
                      provide any assurance of returns to investors. Past
                      performance should not be taken as an indication or
                      guarantee of future performance, and no representation or
                      warranty, express or implied, is made regarding future
                      performance. The price, value of and income from any of
                      the securities or financial instruments mentioned in this
                      report can fall as well as rise.
                    </p>
                    <p>
                      By submitting your details to SMC, you are authorizing us
                      to send promotional communication through
                      Call/Email/SMS/Whatsapp even though you may be registered
                      under DND.
                    </p>
                  </div>
                </div>
              </div>
              <div className="copyrightrow">
                <p>
                  <span>&copy; 2024 SMC. All Right Reserved</span>{" "}
                  {/* <span> */}
                  <span>
                    <NavLink to="/disclaimer">Disclaimer</NavLink>
                  </span>
                  <span>
                    <NavLink to="/privacyPolicy">Privacy Policy</NavLink>
                  </span>
                  <span>
                    <NavLink to="/copyright">Copyright</NavLink>
                  </span>
                  <span>
                    <NavLink to="/testimonials">Testimonials</NavLink>
                  </span>
                  <span>
                    <NavLink to="/siteMap">Sitemap</NavLink>
                  </span>
                  <span>
                    <NavLink to="/terms-and-conditions">
                      Terms&Conditions
                    </NavLink>
                  </span>
                  {/* </span> */}
                  {/* <span>RMS Policy</span> */}
                  {/* <span> Grievance </span> */}
                </p>
                {/* <p><a href='https://triverseadvertising.com/' target='_blank' rel="noreferrer">design : triverse</a></p> */}
              </div>
            </div>
          </div>
          {isMobile && <div style={{ minHeight: 40 }}></div>}
        </div>
      </footer>
    </>
  );
};

export default Footer;
