import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useNavigate, useLocation } from "react-router-dom";

export default function ResearchTabs({ data = [] }) {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    // Find the index of the tab that matches the current route
    const matchingTab = data.findIndex((item) =>
      location.pathname.includes(
        item.category_name.replace(/\s+/g, "-").toLowerCase()
      )
    );
    if (matchingTab !== -1) {
      setValue(matchingTab);
    }
  }, [data, location.pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = (category) => {
    navigate(
      `/research/${category?.category_name
        .replace(/\s+/g, "-")
        .toLowerCase()}/${category.research_category_master_id}`
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        sx={{
          "& .MuiTabs-indicator": {
            display: "none",
          },
        }}
      >
        {data.map((item, index) => (
          <Tab
            key={index}
            label={item.category_name}
            onClick={() => handleClick(item)}
            sx={{
              color: "#FFF",

              textTransform: "none",
              borderRadius: "100px",
              backgroundColor: value === index ? "#fff" : "transparent",
              boxShadow:
                value === index ? "0 2px 5px rgba(0, 0, 0, 0.1)" : "none",

              "&.Mui-selected": {
                border: "none",
                color: "#000",
              },
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
}
