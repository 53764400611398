import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Context } from "../context";

export const Product_Drop = ({ setActive, setIsOpen, menus }) => {
  const { setName, setNum, setEmail, setState, setCity, setShowPath } =
    useContext(Context);

  const emptyField = () => {
    setCity("");
    setEmail("");
    setName("");
    setState("");
    setNum("");
    setActive(!menus);
    setIsOpen(false);
  };
  return (
    <>
      <div className="menubox">
        {/* <div className="sitemapcol">
          <h4>Trading</h4>
          <ul>
            <li>
              <NavLink
                to="/mobile-app"
                onClick={() => {
                  emptyField();
                  setShowPath("product");
                }}
              >
                Mobile App
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/web-app"
                onClick={() => {
                  emptyField();
                  setShowPath("product");
                }}
              >
                Web App
              </NavLink>
            </li>
            <li>Desktop</li>
            <li>API Connect</li>
            <li>
              <NavLink
                to="/product/mtf-activation"
                onClick={() => {
                  emptyField();
                  setShowPath("product");
                }}
              >
                MTF Activation
              </NavLink>
            </li>
          </ul>
        </div> */}
        <div className="sitemapcol">
          <h4 style={{ cursor: "context-menu" }}>Invest</h4>
          <ul>
            <li>
              <NavLink
                to="/product/Equities"
                onClick={() => {
                  emptyField();
                  setShowPath("product");
                }}
              >
                Equities
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/invest/mutual-fund"
                onClick={() => {
                  emptyField();
                  setShowPath("product");
                }}
              >
                Mutual Funds
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/invest/ipo"
                onClick={() => {
                  emptyField();
                  setShowPath("product");
                }}
              >
                IPOs
              </NavLink>
              {/* <Link
                sx={{ textDecoration: "none", cursor: "pointer" }}
                onClick={handleClick}
              >
                IPOs
              </Link> */}
            </li>
            {/* <li>
              <NavLink
                to="/product/us-stocks"
                onClick={() => {
                  emptyField();
                }}
              >
                US Stocks
              </NavLink>
            </li> */}
            {/* <li>
              <NavLink
                to="/fixed-income"
                onClick={() => {
                  emptyField();
                }}
              >
                Fixed Income
              </NavLink>
            </li> */}
            {/* <li>
              <NavLink
                to="https://www.smctradeonline.com/investment-advisory-portfolios"
                target="_blank"
              >
                Intelligent Advisory Portfolio
              </NavLink>
            </li> */}
            <li>
              <NavLink
                to="/invest/refer-earn"
                onClick={() => {
                  emptyField();
                  setShowPath("product");
                }}
              >
                Refer & Earn
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="sitemapcol">
          <h4 style={{ cursor: "context-menu" }}>Tool</h4>
          <ul>
            <li>
              <NavLink
                to="https://autotrender.smcindiaonline.com:8790/"
                target="_blank"
                onClick={() => {
                  emptyField();
                }}
              >
                Autotrender
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/trade/algo-trading-platform"
                onClick={() => {
                  emptyField();
                  setShowPath("product");
                }}
              >
                Algo Trading Platform
              </NavLink>
            </li>
            <li>
              <NavLink
                to="https://refer.smcindiaonline.org/refer-and-earn/?sourceId=1&destinationId=4&org=smc&mobile=null"
                onClick={() => {
                  emptyField();
                  setShowPath("product");
                }}
                target="_blank"
                style={{ lineHeight: "20px" }}
              >
                Refer Subscription Products
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="sitemapcol">
          <h4 style={{ cursor: "context-menu" }}>Telegram channels</h4>
          <ul>
            <li>
              <NavLink
                to="https://subscriptions.smcindiaonline.com:8880/indexcalls"
                target="_blank"
                onClick={() => {
                  emptyField();
                }}
              >
                Index Trading
              </NavLink>
            </li>
            <li>
              <NavLink
                to="https://subscriptions.smcindiaonline.com:8880/equityfunda"
                target="_blank"
                onClick={() => {
                  emptyField();
                }}
              >
                Equity Ka Funda
              </NavLink>
            </li>
            <li>
              <NavLink
                to="https://subscriptions.smcindiaonline.com:8880/technofunda"
                target="_blank"
                onClick={() => {
                  emptyField();
                }}
              >
                Techno Funda
              </NavLink>
            </li>
            <li>
              <NavLink
                to="https://subscriptions.smcindiaonline.com:8880/commodity"
                target="_blank"
                onClick={() => {
                  emptyField();
                }}
              >
                Commodity Mantra
              </NavLink>
            </li>
            <li>
              <NavLink
                to="https://subscriptions.smcindiaonline.com:8880/technofunda"
                target="_blank"
                onClick={() => {
                  emptyField();
                }}
              >
                Maha Combo
              </NavLink>
            </li>

            {/* <li>Currency Research</li>
            <li>Maha Combo</li> */}
          </ul>
        </div>
        {/* <div className="sitemapcol">
          <h4>Other products</h4>
          <ul>
            <li>Distribution</li>
            <li>Advisory</li>
            <li>Insurance</li>
            <li>
              <NavLink
                to="/products/mtf-finanacing"
                onClick={() => {
                  emptyField();
                  setShowPath("product");
                }}
              >
                MTF Financing
              </NavLink>
            </li>
            <li>Banking tie-ups</li>
            <li>NRI</li>
            <li>
              <NavLink
                to="/products/fpi-services"
                onClick={() => {
                  emptyField();
                  setShowPath("product");
                }}
              >
                FPI
              </NavLink>
            </li>
            <li>Clearing </li>
            <li>NPS</li>
          </ul>
        </div> */}
      </div>
    </>
  );
};
