import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Context } from "../../../../../context";
import ResearchTabs from "../../../../../components/ResearchTabs";
import { Box } from "@mui/material";

export const ResearchSidebar = () => {
  const { researchCategory, setResearchCategory } = useContext(Context);
  const researchData = localStorage.getItem("researchData");
  const finalCategory = JSON.parse(researchData);
  const isMobile = window.innerWidth <= 540;
  let filteredArr = researchCategory?.filter(
    (obj) =>
      obj.category_name !== "Fundamental Research" &&
      obj.category_name !== "Technical Research"
  );

  const categoryOrder = [
    "Wise money",
    "Equity Analysis",
    "Derivative Analysis",
    "Commodities Analysis",
    "Commodity Analysis",
    "Currency Analysis",
    "IPO Reports",
    "Mutual Fund Analysis",
  ];

  React.useEffect(() => {
    if (researchCategory?.length < 1) {
      setResearchCategory(finalCategory);
    }
  }, []);

  filteredArr = filteredArr?.sort((a, b) => {
    return (
      categoryOrder.indexOf(a.category_name) -
      categoryOrder.indexOf(b.category_name)
    );
  });

  return (
    <>
      {!isMobile ? (
        <ul>
          {filteredArr?.map((category) => {
            return (
              <li>
                <NavLink
                  to={`/research/${category?.category_name
                    .replace(/\s+/g, "-")
                    .toLowerCase()}/${category.research_category_master_id}`}
                >
                  {category.category_name}
                </NavLink>
              </li>
            );
          })}
        </ul>
      ) : (
        <Box sx={{ p: 0.5 }}>
          <ResearchTabs data={filteredArr} />
        </Box>
      )}
    </>
  );
};
