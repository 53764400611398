import React, { useState } from "react";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import { Link } from "react-router-dom";
import { Form } from "../../../../components/Form";
import banner from "../../../../images/FPI-banner.webp";
import Icon01 from "../../../../images/fpiicon01.webp";
import Icon02 from "../../../../images/fpiicon02.webp";
import Icon03 from "../../../../images/fpiicon03.webp";
import Icon04 from "../../../../images/fpiicon04.webp";
import Icon05 from "../../../../images/fpiicon05.webp";
import Icon06 from "../../../../images/fpiicon06.webp";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import getstarted from "../../../../images/FPI-Get-start.webp";
import { Tick } from "../../../../images/svg/Tick";
import Keyfeature from "../../../../images/keyfeature.webp";
import equity from "../../../../images/equity.webp";
import debt from "../../../../images/borrow.webp";
import derivative from "../../../../images/derivative.webp";
import realstate from "../../../../images/assets.webp";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import MFFunds from "../../../../images/Mutual-Funds.webp";
import Usstocks from "../../../../images/US-Stocks.webp";
import Fixedincome from "../../../../images/Fixed-Income.webp";
import kycprocess from "../../../../images/Kyc-process.webp";
import { Faq } from "../../../../components/Faq";
import Slider from "react-slick";

export const FPIServices = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [showMore, setShowMore] = useState(false);

  const handleViewMoreClick = () => {
    setShowMore(!showMore);
  };
  const [showMore2, setShowMore2] = useState(false);

  const handleViewMoreClick2 = () => {
    setShowMore2(!showMore2);
  };
  const [showMoreProcess, setShowMoreProcess] = useState(false);

  const handleViewMoreClickProcess = () => {
    setShowMoreProcess(!showMoreProcess);
  };
  const settings = {
    dots: false,
    navigation: true,
    loop: true,
    infinite: false,
    slidesPerRow: 3,
    rows: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    speed: 300,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesPerRow: 2,
          rows: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesPerRow: 1,
          rows: 1,
        },
      },
    ],
  };

  return (
    <>
      <Header />
      <section id="banner">
        <div className="banner internal">
          <div className="container">
            <div className="bredcrum">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>FPI Services</li>
              </ul>
            </div>
            <div className="web-container">
              <div className="bannermg">
                <div className="bannerlt">
                  <h1>Unlock the Market A Land Of Billion Opportunity</h1>
                </div>
                <div className="bannergt">
                  <img src={banner} alt="Hassle-free investing" />
                </div>
                <Form />
              </div>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section id="main">
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="col-12 pull-left ">
              <div className="container">
                <div className="row">
                  <div className="web-container">
                    <div className="title">
                      <h3>Why Invest in India?</h3>
                    </div>
                    <div className="learningcenter investinind">
                      <Slider {...settings}>
                        <div>
                          <div
                            className="learningbox investbx bg-white border-1"
                            data-aos="fade-up"
                            data-aos-offset="200"
                            data-aos-duration="600"
                            data-aos-once="true"
                            data-aos-easing="ease-in-sine"
                          >
                            <div className="learningimg m-0">
                              <img
                                src={Icon01}
                                alt="Stable Government at the Centre"
                              />
                            </div>
                            <div className="learningcont text-center  p-3">
                              <p className="w-100 mb-1">
                                <strong>Stable Government at the Centre</strong>
                              </p>
                              <p className="w-100">
                                Mr. Narendra Modi becoming the Prime Minister
                                for the second time with a thumping majority
                                paves a way for a stable government. This means
                                confidence and continuity for the policies and
                                its implementations.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div
                            className="learningbox investbx bg-white border-1"
                            data-aos="fade-up"
                            data-aos-offset="200"
                            data-aos-duration="600"
                            data-aos-once="true"
                            data-aos-easing="ease-in-sine"
                          >
                            <div className="learningimg m-0">
                              <img
                                src={Icon02}
                                alt="Big Focus on Development"
                              />
                            </div>
                            <div className="learningcont text-center  p-3 text-center">
                              <p className="w-100 mb-1">
                                <strong>Big Focus on Development</strong>
                              </p>
                              <p className="w-100">
                                Big focus on exports, infrastructure,
                                agriculture, creation of jobs, rural development
                                in coming years, resulting in a big boost to
                                corporate earning which will enable a bullish
                                trend in the stock markets.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div
                            className="learningbox investbx bg-white border-1"
                            data-aos="fade-up"
                            data-aos-offset="200"
                            data-aos-duration="600"
                            data-aos-once="true"
                            data-aos-easing="ease-in-sine"
                          >
                            <div className="learningimg m-0">
                              <img
                                src={Icon03}
                                alt="One of the Leading Economies"
                              />
                            </div>
                            <div className="learningcont text-center  p-3 text-center">
                              <p className="w-100 mb-1">
                                <strong>One of the Leading Economies</strong>
                              </p>
                              <p className="w-100">
                                The current size of the Indian economy is around
                                USD 2.7 trillion GDP and Prime Minister Modi’s
                                goal is to make it to USD 5 trillion by 2024 and
                                subsequently, to USD 1 trillion by 2030.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div
                            className="learningbox investbx bg-white border-1"
                            data-aos="fade-up"
                            data-aos-offset="200"
                            data-aos-duration="600"
                            data-aos-once="true"
                            data-aos-easing="ease-in-sine"
                          >
                            <div className="learningimg m-0">
                              <img src={Icon04} alt="Leading FPI Destination" />
                            </div>
                            <div className="learningcont text-center  p-3 text-center">
                              <p className="w-100 mb-1">
                                <strong>Leading FPI Destination</strong>
                              </p>
                              <p className="w-100">
                                India is a sought after destination for FDI in
                                view of ease of doing business ranking released
                                by the World Bank going up to 63 from 142 in
                                last five years. It is expected to break in top
                                50 in next few years.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div
                            className="learningbox investbx bg-white border-1"
                            data-aos="fade-up"
                            data-aos-offset="200"
                            data-aos-duration="600"
                            data-aos-once="true"
                            data-aos-easing="ease-in-sine"
                          >
                            <div className="learningimg m-0">
                              <img src={Icon05} alt="Strong Growth Trends" />
                            </div>
                            <div className="learningcont text-center  p-3 text-center">
                              <p className="w-100 mb-1">
                                <strong>Strong Growth Trends</strong>
                              </p>
                              <p className="w-100">
                                If India continues its recent growth trend,
                                average household incomes will triple over the
                                next two decades and it will become the world's
                                fifth largest consumer economy by the year 2025.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div
                            className="learningbox investbx bg-white border-1"
                            data-aos="fade-up"
                            data-aos-offset="200"
                            data-aos-duration="600"
                            data-aos-once="true"
                            data-aos-easing="ease-in-sine"
                          >
                            <div className="learningimg m-0">
                              <img
                                src={Icon06}
                                alt="Large Working Population"
                              />
                            </div>
                            <div className="learningcont text-center  p-3 text-center">
                              <p className="w-100 mb-1">
                                <strong>Large Working Population</strong>
                              </p>
                              <p className="w-100">
                                The proportion of working age population in
                                India is likely to reach more than 64% by 2021,
                                with a large number of young persons in the
                                20-35 age group.
                              </p>
                            </div>
                          </div>
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent p-100 grey-background"
            data-aos="fade-up"
            data-aos-offset="80"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="web-container">
                <div className="title">
                  <h3>Getting Started</h3>
                </div>
                <div className="col-lg-10 m-auto col-12">
                  <div className="row">
                    <div className="col-lg-6 col-sm-6 col-12 d-flex align-items-center">
                      <div className="accordiancolum">
                        <Accordion
                          expanded={expanded === "panel1"}
                          onChange={handleChange("panel1")}
                          className="accordiansummery mb-3"
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Typography>WHO IS AN FPI</Typography>
                          </AccordionSummary>
                          <AccordionDetails className="accordiandetails">
                            <Typography>
                              <p>
                                FPI is a resident in a country other than India,
                                whose securities market regulator is a signatory
                                to IOSCO's MMOU (Appendix A Signatories) or a
                                signatory to a bilateral MOU with SEBI. In case
                                of a Bank, it should be resident of a country
                                whose central bank is a member of the Bank for
                                International Settlements (BIS).
                              </p>
                              <p className="col-12 float-start d-flex">
                                <Tick className="mx-1" />
                                <span className="col-11 float-end">
                                  Unified market access route for all portfolio
                                  investments in India.
                                </span>
                              </p>
                              <p className="col-12 float-start d-flex">
                                <Tick className="mx-1" />
                                <span className="col-11 float-end">
                                  Proposal by SEBI committee – convergence of
                                  all portfolio investment routes, including
                                  FII, Sub Account and QFI.
                                </span>
                              </p>
                              <p className="col-12 float-start d-flex">
                                <Tick className="mx-1" />
                                <span className="col-11 float-end">
                                  Simplified entry norms - no registration
                                  requirement with SEBI.
                                </span>
                              </p>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          expanded={expanded === "panel2"}
                          onChange={handleChange("panel2")}
                          className="accordiansummery mb-3"
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                          >
                            <Typography>ELIGIBILITY</Typography>
                          </AccordionSummary>
                          <AccordionDetails className="accordiandetails">
                            <Typography>
                              <p className="col-12 float-start d-flex">
                                <Tick className="mx-1" />
                                <span className="col-11 float-end">
                                  Investor is from a Country which is a member
                                  of IOSCO, have bilateral MOU with SEBI, whose
                                  central bank is a member of BIS, is listed in
                                  public statement issued by FATF and list of
                                  FATF member country.
                                </span>
                              </p>
                              <p className="col-12 float-start d-flex">
                                <Tick className="mx-1" />
                                <span className="col-11 float-end">
                                  The investor is regulated and supervised in
                                  its home regulations by the market or banking
                                  regulator.
                                </span>
                              </p>
                              <p className="col-12 float-start d-flex">
                                <Tick className="mx-1" />
                                <span className="col-11 float-end">
                                  Has sufficient experience, good track record,
                                  is professionally competent, financially
                                  sound, generally good reputation of fairness
                                  and integrity.
                                </span>
                              </p>
                              <p className="col-12 float-start d-flex">
                                <Tick className="mx-1" />
                                <span className="col-11 float-end">
                                  The investor is fit and proper and legally
                                  allowed to invest in overseas jurisdictions.
                                </span>
                              </p>
                              <p className="col-12 float-start d-flex">
                                <Tick className="mx-1" />
                                <span className="col-11 float-end">
                                  Investor is not a Non Resident Indian/ OCI or
                                  a Resident Indian. If the entity is
                                  non-individual :-
                                </span>
                              </p>
                              <p className="col-12 float-start d-flex">
                                <Tick className="mx-1" />
                                <span className="col-11 float-end">
                                  Investor is not a Non Resident Indian/ OCI or
                                  a Resident Indian. If the entity is
                                  non-individual :-
                                </span>
                              </p>
                              <p>
                                (a) the contribution of a single NRI or OCI or
                                RI shall be below twenty-five percent of the
                                total contribution in the corpus of the
                                applicant.
                              </p>
                              <p>
                                (b) the aggregate contribution of NRIs, OCIs,
                                and RIs shall be below fifty percent of the
                                total contribution in the total corpus.
                              </p>
                              <p>
                                (c) the NRIs, OCI or RI should not be in the
                                control of the applicant.
                              </p>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          expanded={expanded === "panel3"}
                          onChange={handleChange("panel3")}
                          className="accordiansummery mb-3"
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                          >
                            <Typography>CLASSIFICATION</Typography>
                          </AccordionSummary>
                          <AccordionDetails className="accordiandetails">
                            <Typography>
                              <p>
                                As per the circular by SEBI dates 5th November
                                2019, the classifications of FPIs have changed.
                                Now there are only two categories Cat 1 and Cat
                                2, classified as under.
                              </p>
                              <p>
                                <strong>Category I </strong>
                              </p>
                              <p>
                                (a) Government and Government related investors
                                such as central banks, sovereign wealth funds,
                                international or multilateral organizations or
                                agencies, including entities controlled, or at
                                least 75%, directly or indirectly, owned by such
                                Government and Government related investor(s);
                              </p>
                              <p>(b) Pension funds and university funds;</p>
                              <p>
                                (c) Appropriately regulated entities such as
                                insurance or reinsurance entities, banks, asset
                                management companies, investment managers
                                (‘IMs’), investment advisors, portfolio
                                managers, broker dealers and swap dealers;
                              </p>
                              <p>
                                (d) Entities from the Financial Action Task
                                Force (‘FATF’) member countries which are:
                              </p>

                              <p>Appropriately regulated funds;</p>
                              <p>
                                Unregulated funds whose IM is appropriately
                                regulated and registered as a Cat I FPI
                                -Provided that the IM undertakes the
                                responsibility of all the acts of commission or
                                omission of such unregulated fund;
                              </p>
                              <p>
                                University related endowments of such
                                universities that have been in existence for
                                more than five years;
                              </p>

                              <p>(e)An entity:</p>

                              <p>
                                Whose IM is from FATF member country and such an
                                IM is registered as Cat I FPI; or
                              </p>
                              <p>
                                Which is at least 75% owned, directly or
                                indirectly by another entity, eligible under
                                sub-clause (ii), (iii) and (iv) above, and such
                                an eligible entity is from a FATF member
                                country.
                              </p>
                              <p>
                                <strong>Category II </strong>
                              </p>
                              <p>
                                Include all the investors not eligible under
                                Category-I Foreign Portfolio Investor such as
                              </p>
                              <p>
                                (a) Appropriately regulated funds not eligible
                                as Category-I foreign portfolio investor;
                              </p>
                              <p>(b) Endowments and foundations;</p>
                              <p>(c) Charitable organisations;</p>
                              <p>(d) Corporate bodies;</p>
                              <p>(e) Family offices;</p>
                              <p>(f) Individuals;</p>
                              <p>
                                (g) Appropriately regulated entities investing
                                on behalf of their client, as per conditions
                                specified by the Board from time to time;
                              </p>
                              <p>
                                (h) Unregulated funds in the form of limited
                                partnership and trusts.
                              </p>
                              <p>(i) Others</p>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          expanded={expanded === "panel4"}
                          onChange={handleChange("panel4")}
                          className="accordiansummery mb-3"
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                          >
                            <Typography>
                              Omnibus Investing on Behalf of Clients
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails className="accordiandetails">
                            <Typography>
                              <p>
                                <strong>
                                  Another major development in the recent
                                  circular is that a client can now trade as
                                  Omnibus account; this is major step towards
                                  making the market more accessible for the
                                  Foreign Clients.
                                </strong>
                              </p>
                              <p>
                                Appropriately regulated entities such as banks
                                and merchant banks, asset management companies,
                                investment managers, investment advisors,
                                portfolio managers, insurance & reinsurance
                                entities, broker dealers and swap dealers will
                                be permitted to undertake investments on behalf
                                of their clients as Category II FPIs in addition
                                to undertaking proprietary investment by taking
                                separate registrations as Category I FPI.
                              </p>
                              <p>
                                (a) Where such entities are undertaking
                                investments on behalf of their clients, Category
                                II FPI registration shall be granted subject to
                                following conditions: Clients of FPI can only be
                                individuals and family offices.
                              </p>
                              <p>
                                (b) Clients of FPI should also be eligible for
                                registration as FPI and should not be dealing on
                                behalf of third party.
                              </p>
                              <p>
                                (c) If the FPI is from a Financial Action Task
                                Force member country, then the KYC including
                                identification & verification of beneficial
                                owner of the clients of such FPI should be done
                                by the FPI as per requirements of the home
                                jurisdiction of the FPI. FPIs from non-Financial
                                Action Task Force member countries should
                                perform KYC of their clients including
                                identification & verification of beneficial
                                owner as per Indian KYC requirements.
                              </p>
                              <p>
                                (d) FPI has to provide complete investor details
                                of its clients (if any) on quarterly basis (end
                                of calendar quarter) by end of the following
                                month to DDP.
                              </p>
                              <p>
                                (e) Investments made by each such client, either
                                directly as FPI and/or through its investor
                                group shall be clubbed with the investments made
                                by such clients (holding more than 50% in the
                                FPI) through the above referenced appropriately
                                regulated FPIs.
                              </p>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-12">
                      <img src={getstarted} alt="get started" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="authorised">
              <div className="container">
                <div className="row">
                  <div className="web-container">
                    <div className="title">
                      <h3>Features & Benefits</h3>
                    </div>
                    <div className="sellearn">
                      <div className="sestn justify-content-start">
                        <div className="selbox">
                          <div className="selimg">
                            <span>1</span>
                          </div>
                          <div className="selcont">
                            <p>
                              Registrations through Common Application Form
                              (CAF), an online platform by the regulator, to
                              submit necessary documents
                            </p>
                          </div>
                        </div>
                        <div className="selbox">
                          <div className="selimg">
                            <span>2</span>
                          </div>
                          <div className="selcont">
                            <p>
                              Single Dcoumentation for on-boarding under Various
                              services
                            </p>
                          </div>
                        </div>
                        <div className="selbox">
                          <div className="selimg">
                            <span>3</span>
                          </div>
                          <div className="selcont">
                            <p>
                              Experienced Compliance team to ensure smooth
                              on-boarding and guidance.
                            </p>
                          </div>
                        </div>
                        <div className="selbox">
                          <div className="selimg">
                            <span>4</span>
                          </div>
                          <div className="selcont">
                            <p>
                              Quick turnaround time for granting FPI
                              registraiton and custody Set-up
                            </p>
                          </div>
                        </div>
                        <div className="selbox">
                          <div className="selimg">
                            <span>5</span>
                          </div>
                          <div className="selcont">
                            <p>Comprehensive disaster recovery plan.</p>
                          </div>
                        </div>
                        <div className="selbox">
                          <div className="selimg">
                            <span>6</span>
                          </div>
                          <div className="selcont">
                            <p>
                              Dedicated relationship manager to cater all your
                              needs.
                            </p>
                          </div>
                        </div>
                        <div className="selbox">
                          <div className="selimg">
                            <span>7</span>
                          </div>
                          <div className="selcont">
                            <p>
                              Single dedicated desk for FEMA and SEBI
                              compliance.
                            </p>
                          </div>
                        </div>
                        <div className="selbox">
                          <div className="selimg">
                            <span>8</span>
                          </div>
                          <div className="selcont">
                            <p>
                              Tie-ups with reputed consultants for tax related
                              services.
                            </p>
                          </div>
                        </div>
                        <div className="selbox">
                          <div className="selimg">
                            <span>9</span>
                          </div>
                          <div className="selcont">
                            <p>
                              Debt limits monitoring and facilitates for Debt
                              investment bidding under Voluntary Retention Route
                              (VRR).
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="80"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="keyfeatures">
              <div className="container">
                <div className="row">
                  <div className="web-container">
                    <div className="title">
                      <h3>How Its Work</h3>
                    </div>
                    <div className="keyfeaturerow">
                      <div className="keyfeaturecont">
                        <img src={Keyfeature} alt="key feature" />
                      </div>
                      <div className="keyfeaturecont">
                        <ul>
                          <li>
                            <span>1</span>
                            <strong>Issuance of Registration</strong>
                          </li>
                          <li>
                            <span>2</span>
                            <strong>
                              Facilitation in getting Permanent Account Number
                              (PAN) for tax purpose
                            </strong>
                          </li>
                          <li>
                            <span>3</span>
                            <strong>Setting up of Demat, Custody, CSGL</strong>
                          </li>
                          <li>
                            <span>4</span>
                            <strong>Setting up of Trading/ Broking a/c</strong>
                          </li>
                          <li>
                            <span>5</span>
                            <strong>Professional Clearing Services</strong>
                          </li>
                          <li>
                            <span>6</span>
                            <strong>
                              SNRR Accounts, Special Foreign Currency Accounts
                              in major currencies
                            </strong>
                          </li>
                          <li>
                            <span>7</span>
                            <strong>Necessary reporting to regulatory</strong>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3 className="lh-1">Types Of Investments Allowed</h3>
                  </div>
                  <div className="reports d-flex justify-content-between col-12 pull-left gap-2 flex-wrap investallowed">
                    <div className="reportdtn">
                      <div className="reporthead">
                        <img src={equity} alt="Equity" className="mb-1" />
                        <h4>Equity</h4>
                      </div>
                      <div className="reportcont">
                        <p className="col-12 float-start d-flex">
                          <Tick className="mx-1" />
                          <span className="col-10 float-end">
                            Shares, Debentures and Warrants, listed or to be
                            listed (both Primary / Secondary).
                          </span>
                        </p>
                        <p className="col-12 float-start d-flex">
                          <Tick className="mx-1" />
                          <span className="col-10 float-end">
                            Units of domestic Mutual Fund Schemes, whether
                            listed or not.
                          </span>
                        </p>
                        {showMore && (
                          <p className="col-12 float-start d-flex">
                            <Tick className="mx-1" />
                            <span className="col-10 float-end">
                              Indian Depository Receipts (IDRs) & other
                              instruments as permitted from time to time.
                            </span>
                          </p>
                        )}
                      </div>
                      <div className="mbtn">
                        <div className="btnn" onClick={handleViewMoreClick}>
                          <span>{showMore ? "Hide" : "Read more"}</span>
                        </div>
                      </div>
                    </div>
                    <div className="reportdtn">
                      <div className="reporthead">
                        <img src={debt} alt="debt" className="mb-1" />
                        <h4>Debt</h4>
                      </div>
                      <div className="reportcont">
                        <p className="col-12 float-start d-flex">
                          <Tick className="mx-1" />
                          <span className="col-10 float-end">
                            Dated Government Securities.
                          </span>
                        </p>
                        <p className="col-12 float-start d-flex">
                          <Tick className="mx-1" />
                          <span className="col-10 float-end">
                            Commerical Papers issued by an Indian Company.
                          </span>
                        </p>
                        <p className="col-12 float-start d-flex">
                          <Tick className="mx-1" />
                          <span className="col-10 float-end">
                            Security Receipts issued by Asset Reconstruction
                            Companies.
                          </span>
                        </p>
                        {showMore2 && (
                          <>
                            <p className="col-12 float-start d-flex">
                              <Tick className="mx-1" />
                              <span className="col-10 float-end">
                                Perpetual Debt Instruments and Debt Capital
                                Instruments, as specified by RBI from time to
                                time.
                              </span>
                            </p>
                            <p className="col-12 float-start d-flex">
                              <Tick className="mx-1" />
                              <span className="col-10 float-end">
                                Infrastructure NCDs and Bonds.
                              </span>
                            </p>
                          </>
                        )}
                      </div>
                      <div className="mbtn">
                        <div className="btnn" onClick={handleViewMoreClick2}>
                          <span>{showMore2 ? "Hide" : "Read more"}</span>
                        </div>
                      </div>
                    </div>
                    <div className="reportdtn">
                      <div className="reporthead">
                        <img
                          src={derivative}
                          alt="derivative"
                          className="mb-1"
                        />
                        <h4>Derivatives</h4>
                      </div>
                      <div className="reportcont">
                        <p className="col-12 float-start d-flex">
                          <Tick className="mx-1" />
                          <span className="col-10 float-end"> Equity.</span>
                        </p>
                        <p className="col-12 float-start d-flex">
                          <Tick className="mx-1" />
                          <span className="col-10 float-end">
                            Interest Rate.
                          </span>
                        </p>
                        <p className="col-12 float-start d-flex">
                          <Tick className="mx-1" />
                          <span className="col-10 float-end"> Currency.</span>
                        </p>
                      </div>
                    </div>
                    <div className="reportdtn">
                      <div className="reporthead">
                        <img
                          src={realstate}
                          alt="Real Estate Funds"
                          className="mb-1"
                        />
                        <h4>Real Estate Funds</h4>
                      </div>
                      <div className="reportcont">
                        <p className="col-12 float-start d-flex">
                          <Tick className="mx-1" />
                          <span className="col-10 float-end">
                            Alternate Investment Funds.
                          </span>
                        </p>
                        <p className="col-12 float-start d-flex">
                          <Tick className="mx-1" />
                          <span className="col-10 float-end">
                            Real Estate Investment Trusts.
                          </span>
                        </p>
                        <p className="col-12 float-start d-flex">
                          <Tick className="mx-1" />
                          <span className="col-10 float-end">
                            Infrastructure Investment Trusts.
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent p-100 grey-background"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>Process Flow</h3>
                  </div>
                  <div className="col-12 pull-left ipotable defaulttable bg-white processflow">
                    <table className="table m-0 border">
                      <thead>
                        <tr className="gradient text-white">
                          <th>Particulars</th>
                          <th>Category I</th>
                          <th>Category II</th>
                          <th>Indicative Time Frame</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td rowSpan="2">Registrations</td>
                          <td colSpan="2">
                            Provide documentation for Permanent Account Number
                          </td>
                          <td colSpan="2">8 - 15 Days</td>
                        </tr>
                        <tr>
                          <td colSpan="2">
                            Provide documentation for Designated Depository
                            <br /> Participant Registration (Custodian)
                          </td>
                          <td colSpan="2">3 - 5 Days</td>
                        </tr>
                        <tr>
                          <td>SEBI Fees</td>
                          <td rowSpan="3">US $ 3,000</td>
                          <td rowSpan="3">US $ 300</td>
                          <td rowSpan="3"></td>
                        </tr>
                        <tr>
                          <td>(US $)</td>
                        </tr>
                        <tr>
                          <td>Every block of three years</td>
                        </tr>
                        <tr>
                          <td rowSpan="3">Registration done, what next?</td>
                          <td colSpan="2">Open INR Accounts with a Bank</td>
                          <td>2 - 4 Days</td>
                        </tr>
                        <tr>
                          <td colSpan="2">
                            Open a Custody Account with Custodian
                          </td>
                          <td>2 Days</td>
                        </tr>
                        <tr>
                          <td colSpan="2">
                            Open a Brokerage Account with Broker
                          </td>
                          <td>2 - 4 Days</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="webcent"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="600"
            data-aos-once="true"
            data-aos-easing="ease-in-sine"
          >
            <div className="container">
              <div className="row">
                <div className="web-container">
                  <div className="title">
                    <h3>KYC Process</h3>
                    <span>DOCUMENTS REQUIRED</span>
                  </div>
                  <div className="tradebenefit kycprocess">
                    <Tabs>
                      <div>
                        <div className="iconrow noicon gap-3">
                          <TabList>
                            <Tab>
                              <h4>Individuals</h4>
                            </Tab>
                            <Tab>
                              <h4>Non Individuals</h4>
                            </Tab>
                          </TabList>
                        </div>
                      </div>
                      <TabPanel>
                        <div className="ipoodlist ">
                          <div className="d-flex benefitts gap-5">
                            <div className="col-6">
                              <img src={kycprocess} alt="kycprocess" />
                            </div>
                            <div className="col-6 keyfeaturecont">
                              <ul>
                                <li>Proof of Identity (Passport mandatory) </li>
                                <li>Proof of Address </li>
                                <li>Bank Proof </li>
                                <li>Bank Letter</li>
                                <li>PAN </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="ipoodlist ">
                          <div className="d-flex benefitts gap-5">
                            <div className="col-6">
                              <img src={kycprocess} alt="kycprocess" />
                            </div>
                            <div className="col-6 keyfeaturecont">
                              <ul>
                                <li>
                                  Constitutive Documents (MoA, Trust Deed,
                                  Partnership Deed){" "}
                                </li>
                                <li>Regulatory Affiliation details</li>
                                <li>
                                  Bank Letter stating satisfactory relationship
                                  (Cat III only)
                                </li>
                                <li>
                                  Shareholding details and Ultimate
                                  Beneficiaries’ details{" "}
                                </li>
                                {showMoreProcess && (
                                  <>
                                    <li>
                                      {" "}
                                      Senior Management Credentials / Photo
                                    </li>
                                    <li>
                                      Board Resolution stating that entity is
                                      allowed to invest
                                    </li>
                                    <li>Certificate of Incorporation</li>
                                    <li>Proof of Address</li>
                                    <li>Financials</li>
                                    <li>PAN</li>
                                    <li>Authorized Signatories details</li>
                                    <li>Bank Account details</li>
                                  </>
                                )}
                              </ul>
                              <div
                                className="btnn mt-3"
                                onClick={handleViewMoreClickProcess}
                              >
                                <span>
                                  {showMoreProcess ? "Hide" : "Know more"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="ipoodlist ">
                          <div className="d-flex benefitts gap-5">
                            <div className="col-6">
                              <img src={MFFunds} alt="MFFunds" />
                            </div>
                            <div className="col-6">
                              <ul>
                                <li> Upto 5x margins for Intraday/CO Orders</li>
                                <li>Call & Trade facility</li>
                                <li>Up to Rs 30/order brokerage</li>
                                <li>Instant withdrawal within 15 minute</li>
                                <li>Fundamental Data for stock analysis</li>
                                <li>
                                  Margin, brokerage calculator and many more
                                </li>
                                <li>Award-winning trade reliability</li>
                                <li>Support from trading specialist</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="ipoodlist ">
                          <div className="d-flex benefitts gap-5">
                            <div className="col-6">
                              <img src={Usstocks} alt="Usstocks" />
                            </div>
                            <div className="col-6">
                              <ul>
                                <li> Upto 5x margins for Intraday/CO Orders</li>
                                <li>Call & Trade facility</li>
                                <li>Up to Rs 30/order brokerage</li>
                                <li>Instant withdrawal within 15 minute</li>
                                <li>Fundamental Data for stock analysis</li>
                                <li>
                                  Margin, brokerage calculator and many more
                                </li>
                                <li>Award-winning trade reliability</li>
                                <li>Support from trading specialist</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="ipoodlist ">
                          <div className="d-flex benefitts gap-5">
                            <div className="col-6">
                              <img src={Fixedincome} alt="Fixedincome" />
                            </div>
                            <div className="col-6">
                              <ul>
                                <li> Upto 5x margins for Intraday/CO Orders</li>
                                <li>Call & Trade facility</li>
                                <li>Up to Rs 30/order brokerage</li>
                                <li>Instant withdrawal within 15 minute</li>
                                <li>Fundamental Data for stock analysis</li>
                                <li>
                                  Margin, brokerage calculator and many more
                                </li>
                                <li>Award-winning trade reliability</li>
                                <li>Support from trading specialist</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Faq />
        </section>
      </main>
      <Footer />
    </>
  );
};
