import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
const IpoAllotmnetStatus = () => {
    return (
        <div className="howtoapply">
            <Tabs>
                <div className="tradingben">
                    <div className="iconrow noicon">
                        <TabList className="m-0">
                            <Tab>
                                <h4>How to apply in IPO</h4>
                            </Tab>
                            <Tab>
                                <h4>
                                    How to check IPO allotment status
                                </h4>
                            </Tab>
                        </TabList>
                    </div>
                </div>
                <TabPanel>
                    <div className="ipoodlist">
                        <div className="videostn">
                            <div className="videostncol6">
                                <iframe
                                    width="100%"
                                    height="315"
                                    src="https://www.youtube.com/embed/d11SL1HSVlY?rel=0"
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen
                                ></iframe>
                            </div>
                            <div className="videostncol6">
                                <ul>
                                    <li>
                                        Login to SMC App/Website & click on
                                        IPO
                                    </li>
                                    <li>
                                        Select desired IPO & tap on “Apply”
                                    </li>
                                    <li>
                                        Enter UPI ID, set quantity/price &
                                        submit
                                    </li>
                                    <li>
                                        Accept mandate on the UPI app to
                                        complete the process
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="ipoodlist">No Data</div>
                </TabPanel>
            </Tabs>
        </div>
    )
}

export default IpoAllotmnetStatus;